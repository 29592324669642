import React from "react";
import "./Help.css";
import { Container, Accordion, Card } from "react-bootstrap";
import brotato from "./brotato.png";
import local from "./images/help/local.jpg";
import options_modal from "./images/help/options_modal.jpg";
import video_modal from "./images/help/video_modal.jpg";
import video_page from "./images/help/video_page.jpg";

const Help = ({}) => {
	return (
		<>
			<Container>
				<h1 className="mt-5 text-center">Frequently Asked Questions</h1>
				<p className="text-center">
					How did I become a potato? How do I use potato.land? Some
					answers here.
				</p>
				<Accordion className="mt-5 mb-5">
					<Card>
						<Accordion.Toggle as={Card.Header} eventKey="0">
							<h5>How did I become a potato?</h5>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
								<img src={brotato} />
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Accordion.Toggle as={Card.Header} eventKey="1">
							<h5>How do I make or join a couch?</h5>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="1">
							<Card.Body>
								Click <b>Host</b> to host a couch, or enter the
								ID of an existing couch and click <b>Connect</b>{" "}
								to join one!
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Accordion.Toggle as={Card.Header} eventKey="2">
							<h5>How do I invite friends to my couch?</h5>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="2">
							<Card.Body>
								Share either the link to the couch or the Couch
								ID with them! For example, your friends can join
								by either clicking
								https://potato.land/distinct-spud-607 or
								entering distinct-spud-607 and clicking{" "}
								<b>Connect</b> on the landing page!
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Accordion.Toggle as={Card.Header} eventKey="3">
							<h5>How do I input a video source?</h5>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="3">
							<Card.Body>
								<img src={video_modal} className="w-50" />
								<img src={local} className="w-50" />
								<p className="mt-3">
									Click <b>Playlist</b> and enter the video
									source in the popup! Click{" "}
									<i
										className="material-icons"
										style={{ fontSize: "1rem" }}
									>
										playlist_add
									</i>{" "}
									to add more videos to the playlist, drag
									individual boxes around to reorder them, and
									click{" "}
									<i
										className="material-icons"
										style={{ fontSize: "1rem" }}
									>
										delete_outline
									</i>{" "}
									to remove a video from the playlist.
								</p>
								<p>
									Click on <b>Direct Link</b>, <b>YouTube</b>,{" "}
									<b>Other</b>, or <b>Local</b> to select the
									type of video to play:
								</p>
								<ul>
									<li>
										<b>Direct Link:</b> Enter a direct link
										to a video, then click <b>OK</b>. The
										direct link must point to a filetype
										that can be played by an HTML5 player:
										.mp4, .webm, .ogg, .m3u8, etc. work, but
										.mkv does not. Direct download links for
										these filetypes sometimes work too.
									</li>
									<li>
										<b>YouTube:</b> Enter a link to a
										YouTube video, then click <b>OK</b>. The
										video must be public or unlisted.
									</li>
									<li>
										<b>Other:</b> Enter a link to a video
										hosted on one of the supported sites
										(see{" "}
										<b>
											What sites are supported in the
											"Other" tab?
										</b>
										), then click <b>OK</b>.
									</li>
									<li>
										<b>Local:</b> Use the <b>Browse</b> menu
										to select a local video file. All couch
										potatoes must select their own local
										file.
									</li>
								</ul>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Accordion.Toggle as={Card.Header} eventKey="8">
							<h5>
								What sites are supported in the "Other" tab?
							</h5>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="8">
							<Card.Body>
								<p>
									Currently, we support videos from the
									following sites (accepted formats are
									bulleted):
								</p>
								<ul>
									<li>
										<b> Crunchyroll </b>
										<ul>
											<li>
												<font color={"#bfdcf5"}>
													{
														" https://www.crunchyroll.com/<series>/<title>"
													}
												</font>
											</li>
										</ul>
									</li>
									<li>
										<b> Funimation </b>
										<ul>
											<li>
												<font color={"#bfdcf5"}>
													{
														" https://www.funimation.com/shows/<show>/<title>"
													}
												</font>
											</li>
										</ul>
									</li>
									{/* <li>
                                        <b> Dailymotion </b>
                                        <ul>
                                            <li>
                                                <font color={'#bfdcf5'}>
                                                {"https://www.dailymotion.com/video/<id>"}
                                                </font>
                                            </li>
                                        </ul>
                                    </li> */}
									<li>
										<b> Facebook </b>
										<ul>
											<li>
												<font color={"#bfdcf5"}>
													{
														" https://www.facebook.com/watch/?v=<id> "
													}
												</font>
											</li>
											<li>
												<font color={"#bfdcf5"}>
													{
														" https://www.facebook.com/<user>/videos/<id>"
													}
												</font>
											</li>
										</ul>
									</li>
									<li>
										<b> Google Drive </b> (Public Links Only)
										<ul>
											<li>
												<font color={"#bfdcf5"}>
													{
														" https://www.drive.google.com/file/d/<id>/view "
													}
												</font>
											</li>
										</ul>
									</li>
									<li>
										<b> MIT ODL</b> (MIT Account Required)
										<ul>
											<li>
												<font color={"#bfdcf5"}>
													{
														" https://video.odl.mit.edu/videos/<id>"
													}
												</font>
											</li>
										</ul>
									</li>
									<li>
										<b> Streamable </b>
										<ul>
											<li>
												<font color={"#bfdcf5"}>
													{
														" https://streamable.com/<id>"
													}
												</font>
											</li>
										</ul>
									</li>
									{/* <li>
                                        <b> Twitch </b>
                                        <ul>
                                            <li>
                                                <font color={'#bfdcf5'}>
                                                    {" https://www.twitch.tv/<user> "}
                                                </font>
                                            </li>
                                            <li>
                                                <font color={'#bfdcf5'}>
                                                    {" https://www.twitch.tv/videos/<id>"}
                                                </font>
                                            </li>
                                        </ul>
                                    </li> */}
									<li>
										<b> Vimeo </b>
										<ul>
											<li>
												<font color={"#bfdcf5"}>
													{" https://vimeo.com/<id>"}
												</font>
											</li>
										</ul>
									</li>
								</ul>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Accordion.Toggle as={Card.Header} eventKey="3.5">
							<h5>Why doesn't Netflix (or other site) work?</h5>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="3.5">
							<Card.Body>
								<p>
									Currently, we cannot support streaming sites
									that require a subscription for access to
									content. If you have a legal streaming site
									in mind that doesn't require a login, please
									feel free to reach out to us on the{" "}
									<a
										href="https://forms.gle/Yn5Eob2UEksHKHr18"
										target="_blank"
										className="text-info"
									>
										feedback form
									</a>
									!
								</p>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Accordion.Toggle as={Card.Header} eventKey="4">
							<h5>What do the options do?</h5>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="4">
							<Card.Body>
								<img
									src={options_modal}
									className="w-50 mb-3 mr-3 float-left"
								/>
								<p>
									The <b>Audio Chat</b> options adjust
									settings when connected to audio chat. The{" "}
									<b>Microphone sensitivity</b> slider adjusts
									the sensitivity of your microphone input
									when you are unmuted. The{" "}
									<b>Video volume multiplier</b> slider
									adjusts the volume of the video that is
									playing when someone else is speaking. A
									multiplier of 30% means that when someone
									else is speaking, the volume of the
									currently playing video will be reduced to
									30% of the original volume.
								</p>
								<p>
									The Host Potato has an additional{" "}
									<b>Friend Potato Permissions</b>. The Host
									Potato can control whether Friend Potatoes
									can play/pause the video, or edit the
									playlist.
								</p>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Accordion.Toggle as={Card.Header} eventKey="5">
							<h5>
								What do the symbols next to the potatoes mean?
							</h5>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="5">
							<Card.Body>
								<p>
									The symbol to the left of each potato
									indicates whether or not the video has
									loaded for each potato:
								</p>
								<ul>
									<li>
										<i
											className="material-icons mr-1"
											style={{ fontSize: "1rem" }}
										>
											check_circle
										</i>
										: the video is loaded and ready to play.
									</li>
									<li>
										<i
											className="material-icons mr-1"
											style={{ fontSize: "1rem" }}
										>
											sync
										</i>
										: the video has not loaded or is
										buffering.
									</li>
									<li>
										<i
											className="material-icons mr-1"
											style={{ fontSize: "1rem" }}
										>
											check_circle_outline
										</i>
										: the video has finished playing.
									</li>
								</ul>
								The symbol to the right of a potato indicates
								which potato did the latest action:
								<ul>
									<li>
										<i
											className="material-icons mr-1"
											style={{ fontSize: "1rem" }}
										>
											play_arrow
										</i>
										: the potato played the video.
									</li>
									<li>
										<i
											className="material-icons mr-1"
											style={{ fontSize: "1rem" }}
										>
											pause
										</i>
										: the potato paused the video.
									</li>
									<li>
										<i
											className="material-icons mr-1"
											style={{ fontSize: "1rem" }}
										>
											fast_forward
										</i>
										: the potato jumped to a different point
										in the video.
									</li>
									<li>
										<i
											className="material-icons mr-1"
											style={{ fontSize: "1rem" }}
										>
											speed
										</i>
										: the potato changed the video playback
										speed.
									</li>
								</ul>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Accordion.Toggle as={Card.Header} eventKey="7">
							<h5>Why can’t I connect to a couch?</h5>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="7">
							<Card.Body>
								There are a number of reasons we’ve identified
								for why you might be having trouble connecting.
								For example, at the moment connecting to a couch
								while on a VPN or proxy does not work. If you
								are not on a VPN or proxy and are still
								experiencing difficulty connecting, you can try
								restarting your browser. If that still does not
								fix the issue, you can test your WebRTC
								connection{" "}
								<a
									href="https://webrtc.github.io/samples/src/content/peerconnection/trickle-ice/"
									className="text-info"
									target="_blank"
								>
									here
								</a>{" "}
								by clicking <b>Gather candidates</b> at the
								bottom of the page; you should see both “host”
								and “srflx” type candidates appear. Finally, if
								none of these options work, you can contact us
								at{" "}
								<a
									href="mailto:potato.land@mit.edu"
									className="text-info"
								>
									potato.land@mit.edu
								</a>{" "}
								to report the issue.
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
			</Container>
		</>
	);
};

export default Help;
