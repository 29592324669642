import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import * as Constants from "./constants";
import thunk from "redux-thunk";

const initialState = {
	currentPage: Constants.PAGE_SPLASH,
	asyncPeer: { status: Constants.PEER_STATUS_CLOSED, peer: null },
	asyncClientConnection: {
		status: Constants.CLIENT_CONNECTION_STATUS_CLOSED,
		connection: null,
	},
	hostConnections: new Map(),
	ownId: "",
	ownNickname: "",
	isHosting: false,
	roomId: "room ID",
	host: {
		nickname: "host",
		ready: false,
	},
	peerList: new Map(),
	audioPeerList: [],
	videoSource: {
		sources: {},
		subtitleSources: {},
		playlist: [],
	},
	videoAutoplay: true,
	videoPlayer: null,
	uiDarkMode: false,
	clientPermissions: {
		canPlay: true,
		canSource: false,
	},
	latestAction: null,
	audio: {
		joinedAudio: false, // joined audio?
		enableIn: false, // mute mic?
		enableOut: true, // turn off audio sound?
		selectedInput: "", // device Id of selected microphone
		inputs: [], // list of microphones
		speaking: {}, // object mapping people to boolean
		volumeReduced: false, // has the volume been reduced?
		options: {
			gain: 1,
			reduction: 0.3,
		},
	},
};

let enhancer;
if (process.env.NODE_ENV !== "production") {
	console.log("using development environment");
	enhancer = compose(
		applyMiddleware(thunk),
		window.__REDUX_DEVTOOLS_EXTENSION__ &&
			window.__REDUX_DEVTOOLS_EXTENSION__()
	);
} else {
	console.log("this is a production build");
	enhancer = compose(applyMiddleware(thunk));
}

const store = createStore(rootReducer, initialState, enhancer);

/* { OLD VIDEO SOURCE
    type: Constants.VIDEO_SOURCE_TYPE_LOCAL,
    source: null,
    mimeType: null
} */

export default store;
