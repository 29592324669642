// export const URL_BASE = "index.html";
export const URL_BASE = "";

export const PAGE_SPLASH = "PAGE_SPLASH";
export const PAGE_NICKNAME = "PAGE_NICKNAME";
export const PAGE_VIDEO = "PAGE_VIDEO";

export const VIDEO_SOURCE_TYPE_LOCAL = "VIDEO_SOURCE_TYPE_LOCAL";
export const VIDEO_SOURCE_TYPE_URL = "VIDEO_SOURCE_TYPE_URL";
export const VIDEO_SOURCE_TYPE_OTHER = "VIDEO_SOURCE_TYPE_OTHER";
export const VIDEO_SOURCE_TYPE_YOUTUBE = "VIDEO_SOURCE_TYPE_YOUTUBE";

export const SUBTITLE_SOURCE_TYPE_LOCAL = "SUBTITLE_SOURCE_TYPE_LOCAL";
export const SUBTITLE_SOURCE_TYPE_URL = "SUBTITLE_SOURCE_TYPE_URL";
export const SUBTITLE_SOURCE_TYPE_URL_SRT = "SUBTITLE_SOURCE_TYPE_URL_SRT";
export const SUBTITLE_SOURCE_TYPE_URL_VTT = "SUBTITLE_SOURCE_TYPE_URL_VTT";

export const PEER_STATUS_CLOSED = "PEER_STATUS_CLOSED";
export const PEER_STATUS_CONNECTING = "PEER_STATUS_CONNECTING";
export const PEER_STATUS_OPEN = "PEER_STATUS_OPEN";

export const CLIENT_CONNECTION_STATUS_CLOSED =
	"CLIENT_CONNECTION_STATUS_CLOSED";
export const CLIENT_CONNECTION_STATUS_CONNECTING =
	"CLIENT_CONNECTION_STATUS_CONNECTING";
export const CLIENT_CONNECTION_STATUS_OPEN = "CLIENT_CONNECTION_STATUS_OPEN";
export const CLIENT_CONNECTION_STATUS_RECONNECTING =
	"CLIENT_CONNECTION_STATUS_RECONNECTING";
export const CLIENT_CONNECTION_STATUS_ERROR = "CLIENT_CONNECTION_STATUS_ERROR";
//todo: refactor these

export const VIDEO_URL_STATUS_LOADING = "VIDEO_URL_STATUS_LOADING";
export const VIDEO_URL_STATUS_ERROR = "VIDEO_URL_STATUS_ERROR";

export const READY_STATE = {
	READY: "READY",
	UNREADY: "UNREADY",
	ENDED: "ENDED",
};

export const VIDEO_SOURCE_DEFAULT = {
	type: VIDEO_SOURCE_TYPE_URL,
	source: null,
};

export const SUBTITLE_SOURCE_DEFAULT = {
	type: SUBTITLE_SOURCE_TYPE_URL,
	source: null,
};

export const MessageType = {
	INIT_CLIENT: "INIT_CLIENT",
	INIT_HOST: "INIT_HOST",
	RECONNECT_CLIENT: "RECONNECT_CLIENT",
	VIDEO_ACTION: "VIDEO_ACTION",
	VIDEO_SOURCE: "VIDEO_SOURCE",
	VIDEO_SOURCE_SOURCE: "VIDEO_SOURCE_SOURCE",
	VIDEO_SOURCE_ADD: "VIDEO_SOURCE_ADD",
	VIDEO_SOURCE_REMOVE: "VIDEO_SOURCE_REMOVE",
	VIDEO_SOURCE_PLAYLIST: "VIDEO_SOURCE_PLAYLIST",
	SUBTITLE_SOURCE: "SUBTITLE_SOURCE",
	SUBTITLE_SOURCE_SOURCE: "SUBTITLE_SOURCE_SOURCE",
	SUBTITLE_SOURCE_ADD: "SUBTITLE_SOURCE_ADD",
	SUBTITLE_SOURCE_REMOVE: "SUBTITLE_SOURCE_REMOVE",
	PEER_LIST: "PEER_LIST",
	AUDIO_PEER_LIST: "AUDIO_PEER_LIST",
	AUDIO_JOIN: "AUDIO_JOIN",
	READY: "READY",
	CLIENT_PERMISSIONS: "CLIENT_PERMISSIONS",
	QUERY_STATE: "QUERY_STATE",
	QUERY_STATE_RESPONSE: "QUERY_STATE_RESPONSE",
	LATEST_ACTION: "LATEST_ACTION",
};

export const VideoActionType = {
	PLAY: "PLAY",
	PAUSE: "PAUSE",
	SEEK: "SEEK",
	CHANGE_RATE: "CHANGE_RATE",
};

export const VideoFormats = {
	MP4: "video/mp4",
	YOUTUBE: "video/youtube",
	M3U8: "application/x-mpegURL",
	DASH: "application/dash+xml",
};

export const HOST_PEER_DISCONNECT_TIMEOUT_MS = 5000;
export const CONNECTION_POLL_COUNT_MAX = 10;

export const ZOOMERS_BACKEND_HOST = "zoomers-backend.herokuapp.com";
export const ZOOMERS_BACKEND_URL = "https://zoomers-backend.herokuapp.com";
export const ZOOMERS_BACKEND_PORT =
	process.env.NODE_ENV !== "production" ? 80 : 443;
export const ZOOMERS_STREAM_URL = "https://zoomers-stream.herokuapp.com";
export const ZOOMERS_STREAM_STREAM = "/stream";
export const GOOGLE_API_KEY = "AIzaSyBJHr9FTR9J-Q-D11UgRi5icF1JRhUXHss";