import Peer from "peerjs";

export const Actions = {
	CURRENT_PAGE_SET: "CURRENT_PAGE_SET",
	ASYNC_PEER_SET: "ASYNC_PEER_SET",
	ASYNC_CLIENT_CONNECTION_SET: "ASYNC_CLIENT_CONNECTION_SET",
	OWN_ID_SET: "OWN_ID_SET",
	OWN_NICKNAME_SET: "OWN_NICKNAME_SET",
	IS_HOSTING_SET: "IS_HOSTING_SET",
	ROOM_ID_SET: "ROOM_ID_SET",
	HOST_SET: "HOST_SET",
	PEER_LIST_SET: "PEER_LIST_SET",
	AUDIO_PEER_LIST_SET: "AUDIO_PEER_LIST_SET",
	VIDEO_SOURCE_SET: "VIDEO_SOURCE_SET",
	VIDEO_AUTOPLAY_SET: "VIDEO_AUTOPLAY_SET",
	VIDEO_PLAYER_SET: "VIDEO_PLAYER_SET",
	UI_DARK_MODE_SET: "UI_DARK_MODE_SET",
	LATEST_ACTION_SET: "LATEST_ACTION_SET",

	// VIDEO_SOURCE_TYPE_SET: "VIDEO_SOURCE_TYPE_SET",
	// VIDEO_SOURCE_URL_SET: "VIDEO_SOURCE_URL_SET",
	VIDEO_SOURCE_SOURCES_ADD: "VIDEO_SOURCE_SOURCES_ADD",
	VIDEO_SOURCE_SOURCES_REMOVE: "VIDEO_SOURCE_SOURCES_REMOVE",
	VIDEO_SOURCE_PLAYLIST_SET: "VIDEO_SOURCE_PLAYLIST_SET",
	VIDEO_SOURCE_SUBTITLE_SOURCES_INIT: "VIDEO_SOURCE_SUBTITLE_SOURCES_INIT",
	VIDEO_SOURCE_SUBTITLE_SOURCES_SET: "VIDEO_SOURCE_SUBTITLE_SOURCES_SET",
	VIDEO_SOURCE_SUBTITLE_SOURCES_ADD: "VIDEO_SOURCE_SUBTITLE_SOURCES_ADD",
	VIDEO_SOURCE_SUBTITLE_SOURCES_REMOVE: "VIDEO_SOURCE_SUBTITLE_SOURCES_REMOVE",
	VIDEO_SOURCE_SUBTITLE_SOURCES_CLEAR: "VIDEO_SOURCE_SUBTITLE_SOURCES_CLEAR",

	CLIENT_PERMISSIONS_SET: "CLIENT_PERMISSIONS_SET",

	HOST_CONNECTION_ADD: "HOST_CONNECTION_ADD",
	HOST_CONNECTION_REMOVE: "HOST_CONNECTION_REMOVE",

	PEER_LIST_ADD: "PEER_LIST_ADD",
	PEER_LIST_REMOVE: "PEER_LIST_REMOVE",

	AUDIO_JOINED_AUDIO_SET: "AUDIO_JOINED_AUDIO_SET",
	AUDIO_ENABLE_IN_SET: "AUDIO_ENABLE_IN_SET",
	AUDIO_ENABLE_OUT_SET: "AUDIO_ENABLE_OUT_SET",
	AUDIO_SELECTED_INPUT_SET: "AUDIO_SELECTED_INPUT_SET",
	AUDIO_INPUTS_SET: "AUDIO_INPUTS_SET",
	AUDIO_SPEAKING_SET: "AUDIO_SPEAKING_SET",
	AUDIO_VOLUME_REDUCED_SET: "AUDIO_VOLUME_REDUCED_SET",
	AUDIO_OPTIONS_GAIN_SET: "AUDIO_OPTIONS_GAIN_SET",
	AUDIO_OPTIONS_REDUCTION_SET: "AUDIO_OPTIONS_REDUCTION_SET",
};

export const actionStateKey = {
	CURRENT_PAGE_SET: "currentPage",
	ASYNC_PEER_SET: "asyncPeer",
	ASYNC_CLIENT_CONNECTION_SET: "asyncClientConnection",
	OWN_ID_SET: "ownId",
	OWN_NICKNAME_SET: "ownNickname",
	IS_HOSTING_SET: "isHosting",
	ROOM_ID_SET: "roomId",
	HOST_SET: "host",
	PEER_LIST_SET: "peerList",
	AUDIO_PEER_LIST_SET: "audioPeerList",
	VIDEO_SOURCE_SET: "videoSource",
	VIDEO_AUTOPLAY_SET: "videoAutoplay",
	VIDEO_PLAYER_SET: "videoPlayer",
	UI_DARK_MODE_SET: "uiDarkMode",
	CLIENT_PERMISSIONS_SET: "clientPermissions",
	LATEST_ACTION_SET: "latestAction",
};

export function createAction(type, data) {
	return {
		type: type,
		data: data,
	};
}
